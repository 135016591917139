import _ from 'lodash'
import AuditableEntity from '@/modules/common/domain/auditableEntity'
import { Entity } from '@/modules/common/domain/entity'
import moment from 'moment'
import User from '@/modules/user/domain/user'
import Company from '@/modules/company/domain/company'
import Order from '@/modules/order/domain/order'
import Invoice from '@/modules/invoice/domain/invoice'
import SummaryItem from '@/modules/order/statement/domain/orderStatmentSmmaryItem'
import EntityMap from '@/modules/common/domain/entityMap'
import Item from '@/modules/item/domain/Item'

class OrderStatement extends Entity implements AuditableEntity {
  number?: string
  date?: moment.Moment | null
  orders: Array<Order> = []
  invoices: Array<Invoice> = []
  subscriber?: Company | null
  totalPrice?: number
  totalVat?: number
  note?: string
  createdBy?: User | null
  updatedBy?: User | null

  constructor (data: any) {
    super(data)
    this.date = this.convertDate(data.date)
    this.orders = data.orders ? data.orders.map((order: any) => new Order(order)) : []
    this.invoices = data.invoices ? data.invoices.map((invoice: any) => new Invoice(invoice)) : []
    this.subscriber = data.subscriber == null ? null : new Company(data.subscriber)
    this.createdBy = data.createdBy == null ? null : new User(data.createdBy)
    this.updatedBy = data.updatedBy == null ? null : new User(data.updatedBy)

    this.extendSearchString()
  }

  get formattedNumber () {
    return this.number ? 'V-' + this.number : null
  }

  get currency () {
    return (this.orders || []).length > 0 ? this.orders[0].currency : null
  }

  get totalWithoutVat () {
    return _.isNil(this.totalPrice) || _.isNil(this.totalVat) ? 0 : this.totalPrice - this.totalVat
  }

  get totalInvoiced () {
    return _.sumBy(this.invoices, 'totalWithoutVat')
  }

  get paymentType () {
    return this.subscriber ? this.subscriber.saleSettings!.paymentType : null
  }

  get ordersDateFrom () {
    return _(this.orders).map('date').min() || null
  }

  get ordersDateTo () {
    return _(this.orders).map('date').max() || null
  }

  get itemSummaries () {
    const itemSummaries: Array<SummaryItem> = []
    _(this.orders || []).flatMap('items').forEach(orderItem => {
      const summaryItem = _.find(itemSummaries, { itemId: orderItem.item.id, price: orderItem.itemPrice })
      if (summaryItem) {
        summaryItem.delivered += orderItem.delivered
      } else {
        itemSummaries.push(new SummaryItem(orderItem.item.id, orderItem.item, orderItem.itemPrice, orderItem.vatRate, orderItem.delivered))
      }
    })
    return itemSummaries
  }

  private extendSearchString () {
    this.searchString = this.formattedNumber || ''
    if (this.subscriber) {
      this.searchString += this.subscriber.searchString
    }
  }

  enrich (data: any) {
    const { partnersMap }: { itemsMap: EntityMap<Item>, partnersMap: EntityMap<Company>, company: Company } = data
    this.orders.forEach(order => order.enrich(data))
    if (partnersMap) {
      this.subscriber = partnersMap[this.subscriber!.id!]
    }
    this.extendSearchString()
    super.enrich(data)
    return this
  }
}

export default OrderStatement
